.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* based on MUI textfield */
textarea {
  font-family: Sarabun;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4375em;
  letter-spacing: 0.5px;
  color: #212121;
  position: relative;
  cursor: text;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 8.5px 14px;
}

textarea:hover {
  border: 1px solid #000000;
}

textarea:focus {
  border: 1px solid #0079ff;
  outline: 1px solid #0079ff;
}

.modal-fill-reason-textarea:focus {
  outline: none !important;
  border: 1px solid #ef5350;
  outline: 1px solid #ef5350;
}

.campaign-progress-update-textarea {
  outline: none !important;
  border: 1px solid #ef5350;
  outline: 1px solid #ef5350;
}

.Toastify__toast-body {
  font-family: 'Sarabun';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
